import axiosInterceptor from '../../shared/utils/axios'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import ls, {clear} from 'local-storage'
import {
  BodyIProps,
  DataIProps,
  ConfigIProps
} from './authTypes'

// A  function making an async request for user token
export function getToken(data: DataIProps, config: ConfigIProps) {
  return new Promise<any>((resolve, reject) => {
    const body: BodyIProps = {
      GrantType: 'authorization_code',
      Code: data.code,
      ClientId: config.clientId,
      RedirectUri: `https://${window.location.host}/login/callback`,
      CodeVerifier: data.codeChallenge
    }

    const axiosConfig: any = {
      method: 'post',
      url: config.tokenURL,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      },
      data: body
    }

    axiosInterceptor(axiosConfig)
    .then((response) => {
      const userData: any = jwtDecode(response.data.IdToken)
      userData.initials = userData.given_name?.charAt(0) + userData.family_name?.charAt(0)
      ls('IdToken', response.data.IdToken)
      ls('RefreshToken', response.data.RefreshToken)
      ls('AccessToken', response.data.AccessToken)
      resolve(userData)
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        /// Request canceled...
      } else {
        reject((error?.response?.data?.message) ? error.response.data.message : error.message)
      }
    })

  })
}

// A  function making an async request for user token
export function refreshToken(baseURL:string) {
  return new Promise<any>((resolve) => {
    const accessToken = ls('AccessToken')
    const idToken = ls('IdToken')
    const refreshToken = ls('RefreshToken')

    const axiosConfig: any = {
      method: 'post',
      url: baseURL + '/auth/refresh',
      withCredentials: true,
      headers: {
        Authorization: accessToken,
        Authentication: idToken,
        'X-Content-Type-Options': 'nosniff'
      },
      data: { RefreshToken: refreshToken }
    }

    axios(axiosConfig)
    .then((res) => {
      if (res?.data?.IdToken && res?.data?.AccessToken) {
        ls('IdToken', res.data.IdToken)
        ls('AccessToken', res.data.AccessToken)
        resolve(res.data)
      } else {
        resolve({ type: 'info', text: 'Your Session Expired' })
      }
      resolve({ type: 'info', text: 'Your Session Expired' })
    })
    .catch(() => {
      clear()
      resolve({ type: 'info', text: 'Your Session Expired' })
      window.location.replace('/');
    })

  })
}

export function startLogout(config: ConfigIProps) {
  return new Promise<any>((resolve) => {
    const accessToken = ls('AccessToken')
    const idToken = ls('IdToken')
    const refreshToken = ls('RefreshToken')

    const axiosConfig: any = {
      method: 'post',
      url: config.baseURL + '/auth/revoke',
      withCredentials: true,
      headers: {
        Authorization: accessToken,
        Authentication: idToken,
        'X-Content-Type-Options': 'nosniff'
      },
      data: { RefreshToken: refreshToken }
    }

    axios(axiosConfig)
    .then(() => {
      clear()
      resolve({ type: 'success', text: 'Successfully logged out' })
    })
    .catch(() => {
      clear()
      resolve({ type: 'error', text: 'Error logging out of session' })
    })

  })
}