import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  cancelPackage,
  getCategoryTemplates,
  getTemplateCategories
} from './esignTemplatesApi'
import axios from 'axios'
import {
  fetchPackageDocumentsList,
  fetchPackage,
  fetchPackages,
  reloadPackage,
  submitPackage,
  unlockPackageParty,
  fetchPackageAuditLog,
  fetchPackageDocs,
  fetchCreatePackageUserConfig
} from './packagesApi'
import { beginUploadDocuments, getDocumentStatus, uploadFileToS3 } from './esignFileUploadApi'
import {
  ICreatePackageSubmit,
  IESignPackagesState,
  ILocalDocument,
} from '../../../types/esign/createPackage'
import { selectConfig } from '../global/globalSlice'
import {
  concatValues,
  convertFilteredIndexes,
  packageStatusesArr
} from '../../shared/utils/helpers'
import { SortOrder } from '../../../types/ReactTable'
import { DocDownloadType, PackageStatusType } from '../../enums'
import { transformPropToPascal } from '../../shared/utils/transformPropToPascal'
import { retrieveS3Document } from '../../shared/utils/retrieveS3Document'
import { RootState } from '../store'
import { getMappedUploadStatus } from '../../eSign/utils/documentUploadStatusMapper'
import { DocumentUploadStatus } from '../../eSign/utils/enums'

export const initialState: IESignPackagesState = {
  isFetching: false,
  isReFetching: false,
  createPackage: {
    packageDetails: {
      templates: [],
      consentWindow: null,
      eSignExp: null,
      sessionExp: null,
      enableMFA: false,
      enableKBA: false,
      packageName: '',
      kbaQuestions: []
    },
    uploads: [],
    allUploadsComplete: false,
    uploadId: null,
    signersList: [],
    templateCategories: [],
    categoryTemplates: [],
    isSubmitting: false,
    createPackageError: null,
    userConfig: null
  },
  packageDetails: null,
  dashboardPackages: {
    items: [],
    totalCount: 0,
    totalPageCount: 0
  },
  isCancelingPackage: false,
  isUnlockingParty: null,
  packageDocumentsListDictionary: {
    [DocDownloadType.Individual]: [],
    [DocDownloadType.MergedDocs]: [],
    [DocDownloadType.AuditLog]: []
  }
}

export interface IFetchPackagesQuery {
  term?: string
  page?: number
  pageSize?: number
  sortColumn?: string
  isDescending?: SortOrder
  statusesArr?: number[]
}

export const fetchPackagesAsync = createAsyncThunk(
  'esign/fetchPackages',
  async (
    queryData: IFetchPackagesQuery,
    {
      getState,
      rejectWithValue,
      signal
    }: { getState: () => any; rejectWithValue: any; signal: any }
  ) => {
    const config: any = selectConfig(getState() as any)
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })

    const { term, page, pageSize, sortColumn, isDescending, statusesArr } = queryData

    const concatStatuses = statusesArr
      ? concatValues(convertFilteredIndexes(statusesArr, packageStatusesArr()))
      : concatValues(packageStatusesArr())
    const isDesc = isDescending === 'desc' ? true : false
    try {
      const response = await fetchPackages(
        'term=' + term
        + '&page=' + (page ? page + 1 : 1)
        + '&pageSize=' + pageSize
        + '&sortColumn=' + sortColumn
        + '&isDescending=' + isDesc
        + '&statuses=' + concatStatuses,
        source.token,
        config
      )
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  }
)

export const fetchPackageAsync = createAsyncThunk(
  'esign/fetchPackage',
  async (
    packageId: string,
    { getState, rejectWithValue }: { getState: () => any; rejectWithValue: any }
  ) => {
    const config: any = selectConfig(getState() as any)
    try {
      const response = await fetchPackage(packageId, config)
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  }
)

export const reloadPackageAsync = createAsyncThunk(
  'esign/reloadPackage',
  async (
    packageId: string,
    { getState, rejectWithValue }: { getState: () => any; rejectWithValue: any }
  ) => {
    const config: any = selectConfig(getState() as any)
    try {
      const response = await reloadPackage(packageId, config)
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  }
)

export const getTemplateCategoriesAsync = createAsyncThunk(
  'esign/categories',
  async (_, { getState, rejectWithValue, signal }) => {
    const config: any = selectConfig(getState() as any)
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    try {
      const response = await getTemplateCategories(config)
      return response
    } catch (err: any) {
      return rejectWithValue('Error')
    }
  }
)

export const getCategoryTemplatesAsync = createAsyncThunk(
  'esign/templates',
  async (id: string, { getState, rejectWithValue, signal }) => {
    const config: any = selectConfig(getState() as any)
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    try {
      const response = await getCategoryTemplates(id, config)
      return response
    } catch (err: any) {
      return rejectWithValue('Error')
    }
  }
)

export const submitPackageAsync = createAsyncThunk(
  'esign/submitPackage',
  async (
    data: { hasUpload: boolean, readyPackage: ICreatePackageSubmit },
    { getState, rejectWithValue }: { getState: () => any; rejectWithValue: any }
  ) => {
    const { hasUpload, readyPackage } = data
    const config: any = selectConfig(getState() as any)
    try {
      const response = await submitPackage(hasUpload, readyPackage, config)
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  }
)

export const cancelPackageAsync = createAsyncThunk(
  'esign/cancelPackage',
  async (id: string, { getState, rejectWithValue, signal }) => {
    const config: any = selectConfig(getState() as any)
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    try {
      const response = await cancelPackage(id, config)
      return response
    } catch (err: any) {
      return rejectWithValue('Error')
    }
  }
)

export const unlockPackagePartyAsync = createAsyncThunk(
  'esign/unlockPackageParty',
  async (data: any, { getState, rejectWithValue, signal }) => {
    const { packageId, solexPartyId } = data
    const config: any = selectConfig(getState() as any)
    const source = axios.CancelToken.source()
    signal.addEventListener('abort', () => {
      source.cancel()
    })
    try {
      const response = await unlockPackageParty(packageId, solexPartyId, config)
      return response
    } catch (err: any) {
      return rejectWithValue('Error')
    }
  }
)

export const fetchPackageDocumentsListAsync = createAsyncThunk(
  'esign/fetchPackageDocumentsList',
  async (
    queryObj: { transactionId: string | undefined },
    { getState, rejectWithValue }
  ) => {
    const config: any = selectConfig(getState() as any)
    const query = 'SessionId=' + queryObj.transactionId + '&MergeDocs=false'
    try {
      const response = await fetchPackageDocumentsList(query, config)
      return response.data.DocList
    } catch (err: any) {
      return rejectWithValue(err)
    }
  }
)

export const fetchPackageDocsAsync = createAsyncThunk(
  'esign/fetchPackageDocs',
  async (packageId: string, { getState, rejectWithValue }) => {
    const config: any = selectConfig(getState() as any)
    try {
      const response = await fetchPackageDocs(packageId, false, config)
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  }
)

export const fetchPackageDocsMergedAsync = createAsyncThunk(
  'esign/fetchPackageDocsMerged',
  async (packageId: string, { getState, rejectWithValue }) => {
    const config: any = selectConfig(getState() as any)
    try {
      const response = await fetchPackageDocs(packageId, true, config)
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  }
)

export const fetchPackageAuditLogAsync = createAsyncThunk(
  'esign/fetchPackageAuditLog',
  async (packageId: string, { getState, rejectWithValue }) => {
    const config: any = selectConfig(getState() as any)
    try {
      const response = await fetchPackageAuditLog(packageId, config)
      return response
    } catch (err: any) {
      return rejectWithValue(err)
    }
  }
)

export const openPresignedUrl = createAsyncThunk(
  'esign/openPresignUrl',
  async (queryObj: { presignedUrl: string }, { rejectWithValue }) => {
    try {
      return await retrieveS3Document(queryObj.presignedUrl)
    } catch (err: any) {
      return rejectWithValue(err)
    }
  }
)

export const beginUploadDocumentsAsync = createAsyncThunk(
  'esign/uploadDocuments',
  async (
    data: { files: File[], filesDetails: ILocalDocument[] },
    { getState, dispatch }
  ) => {
    const { files, filesDetails } = data
    const config: any = selectConfig(getState() as any)
    const uploadId: any = selectUploadId(getState() as any)

    // Send the documents to the receive the pre-sign url to the future provisioned bucket
    const res: any = await beginUploadDocuments(
      uploadId,
      files,
      config
    )
    const { newUploadId, preSignedUrls } = res

    if (!uploadId) {
      dispatch(setCreatePackageUploadId(newUploadId))
    }
    for (let i = 0; i < filesDetails.length; i++) {
      const file = files[i]
      const fileDetails = filesDetails[i]
      const upload: any = await uploadFileToS3(preSignedUrls[i].url, file)
      if (upload?.status === 200) {
        dispatch(uploadDocumentSuccess(fileDetails))
      } else {
        dispatch(uploadDocumentError(fileDetails))
      }
    }
  }
)

export const getDocumentStatusAsync = createAsyncThunk(
  'esign/uploadDocuments/{UploadId}/status',
  async (
    filesDetails: ILocalDocument[],
    { getState }
  ) => {
    const config: any = selectConfig(getState() as any)
    const uploadId: any = selectUploadId(getState() as any)
    const fileNames = filesDetails.map((document) => document.name);

    // Send the documents to get their status
    return await getDocumentStatus(
      uploadId,
      fileNames,
      config
    );
  }
)

export const fetchCreatePackageUserConfigAsync = createAsyncThunk(
  'esign/fetchCreatePackageUserConfig',
  async (_, { rejectWithValue, getState }) => {
    const config: any = selectConfig(getState() as any)
    try {
      return await fetchCreatePackageUserConfig(config)
    } catch (err: any) {
      return rejectWithValue(err)
    }
  }
)

export const esignSlice = createSlice({
  name: 'esign',
  initialState,
  reducers: {
    resetCreatePackage: (state: IESignPackagesState) => {
      state.createPackage = initialState.createPackage
    },
    updatePackage: (state: IESignPackagesState, action: PayloadAction<any>) => {
      state.createPackage = action.payload
      state.createPackage.createPackageError = null
    },
    cancelCreatePackage: (state: IESignPackagesState) => {
      state.createPackage = initialState.createPackage
    },
    resetPackageDetails: (state: IESignPackagesState) => {
      state.packageDetails = initialState.packageDetails
    },
    resetDocumentsListDictionary: (state: IESignPackagesState) => {
      state.packageDocumentsListDictionary = initialState.packageDocumentsListDictionary
    },
    setCreatePackageUploadId: (state: IESignPackagesState, action: PayloadAction<string | null>) => {
      state.createPackage.uploadId = action.payload
    },
    uploadDocumentSuccess: (state: IESignPackagesState, action: PayloadAction<ILocalDocument>) => {
      const updatePackage = Object.assign({}, state.createPackage)
      const targetIndex = updatePackage.uploads.findIndex(((item: ILocalDocument) => item.name === action.payload.name));

      updatePackage.uploads[targetIndex].uploading = true
      updatePackage.uploads[targetIndex].errorMessage = null
      updatePackage.uploads[targetIndex].errorUploading = false
      updatePackage.allUploadsComplete = false;

      state.createPackage = updatePackage
      state.createPackage.createPackageError = null
    },
    uploadDocumentError: (state: IESignPackagesState, action: PayloadAction<ILocalDocument>) => {
      const updatePackage = Object.assign({}, state.createPackage)
      const targetIndex = updatePackage.uploads.findIndex(((item: ILocalDocument) => item.name === action.payload.name))

      updatePackage.uploads[targetIndex].uploading = false
      updatePackage.uploads[targetIndex].errorMessage = null
      updatePackage.uploads[targetIndex].errorUploading = true

      state.createPackage = updatePackage
      state.createPackage.createPackageError = null
    },
  },
  extraReducers: builder => {
    builder
      // FETCH PACKAGES
      .addCase(fetchPackagesAsync.pending, (state: IESignPackagesState) => {
        state.isFetching = true
      })
      .addCase(
        fetchPackagesAsync.fulfilled,
        (state: IESignPackagesState, action: PayloadAction<any>) => {
          state.isFetching = false
          state.dashboardPackages = {
            items: action.payload?.items,
            totalCount: action.payload?.totalItemCount,
            totalPageCount: action.payload?.totalPageCount
          }
        }
      )
      .addCase(fetchPackagesAsync.rejected, (state: IESignPackagesState) => {
        state.isFetching = false
        state.dashboardPackages = {
          items: [],
          totalCount: 0,
          totalPageCount: 0
        }
      })
      // FETCH PACKAGE
      .addCase(fetchPackageAsync.pending, (state: IESignPackagesState) => {
        state.isFetching = true
      })
      .addCase(
        fetchPackageAsync.fulfilled,
        (state: IESignPackagesState, action: PayloadAction<any>) => {
          state.isFetching = false
          state.packageDetails = action.payload
        }
      )
      .addCase(fetchPackageAsync.rejected, (state: IESignPackagesState) => {
        state.isFetching = false
        state.packageDetails = null
      })
      // RELOAD PACKAGE
      .addCase(reloadPackageAsync.pending, (state: IESignPackagesState) => {
        state.isReFetching = true
      })
      .addCase(
        reloadPackageAsync.fulfilled,
        (state: IESignPackagesState, action: PayloadAction<any>) => {
          state.isReFetching = false
          state.packageDetails = action.payload
        }
      )
      .addCase(reloadPackageAsync.rejected, (state: IESignPackagesState) => {
        state.isReFetching = false
        state.packageDetails = null
      })
      // GET TEMPLATE CATEGORIES
      .addCase(
        getTemplateCategoriesAsync.pending,
        (state: IESignPackagesState) => {
          state.isFetching = true
        }
      )
      .addCase(
        getTemplateCategoriesAsync.fulfilled,
        (state: IESignPackagesState, action: PayloadAction<any>) => {
          state.isFetching = false
          state.createPackage.templateCategories = action.payload
        }
      )
      .addCase(
        getTemplateCategoriesAsync.rejected,
        (state: IESignPackagesState) => {
          state.isFetching = false
          state.createPackage.templateCategories = []
        }
      )
      // GET CATEGORY TEMPLATE
      .addCase(
        getCategoryTemplatesAsync.pending,
        (state: IESignPackagesState) => {
          state.isFetching = true
        }
      )
      .addCase(
        getCategoryTemplatesAsync.fulfilled,
        (state: IESignPackagesState, action: PayloadAction<any>) => {
          state.isFetching = false
          state.createPackage.categoryTemplates = action.payload
        }
      )
      .addCase(
        getCategoryTemplatesAsync.rejected,
        (state: IESignPackagesState) => {
          state.isFetching = false
          state.createPackage.categoryTemplates = []
        }
      )
      // CANCEL PACKAGE
      .addCase(cancelPackageAsync.pending, (state: IESignPackagesState) => {
        state.isCancelingPackage = true
      })
      .addCase(cancelPackageAsync.fulfilled, (state: IESignPackagesState) => {
        if (state.packageDetails) {
          state.packageDetails.status = PackageStatusType.Cancelled
        }
        state.isCancelingPackage = false
      })
      .addCase(cancelPackageAsync.rejected, (state: IESignPackagesState) => {
        state.isCancelingPackage = false
      })
      // UNLOCK PARTY
      .addCase(
        unlockPackagePartyAsync.pending,
        (state: IESignPackagesState, action: any) => {
          state.isUnlockingParty =
            action?.meta && action?.meta?.arg?.solexPartyId
              ? action?.meta?.arg?.solexPartyId
              : null
        }
      )
      .addCase(
        unlockPackagePartyAsync.fulfilled,
        (state: IESignPackagesState) => {
          state.isUnlockingParty = null
        }
      )
      .addCase(
        unlockPackagePartyAsync.rejected,
        (state: IESignPackagesState) => {
          state.isUnlockingParty = null
        }
      )
      // SUBMIT PACKAGE
      .addCase(submitPackageAsync.pending, (state: IESignPackagesState) => {
        state.createPackage.isSubmitting = true
        state.createPackage.createPackageError = null
      })
      .addCase(submitPackageAsync.fulfilled, (state: IESignPackagesState) => {
        state.createPackage.isSubmitting = true
      })
      .addCase(
        submitPackageAsync.rejected,
        (state: IESignPackagesState, action: PayloadAction<any>) => {
          state.createPackage.isSubmitting = false
          state.createPackage.createPackageError = action.payload.message
        }
      )
      // Fetch Audit Log
      .addCase(fetchPackageAuditLogAsync.pending, (state: any) => {
        state.packageDocumentsListDictionary = {
          ...state.packageDocumentsListDictionary,
          [DocDownloadType.AuditLog]: []
        }
        state.alertUser = null
      })
      .addCase(
        fetchPackageAuditLogAsync.fulfilled,
        (state: any, action: any) => {
          // TODO: remove casing transform at some point
          const updatedPayload = transformPropToPascal(action.payload)
          //
          state.packageDocumentsListDictionary = {
            ...state.packageDocumentsListDictionary,
            [DocDownloadType.AuditLog]: [updatedPayload]
          }
        }
      )
      .addCase(fetchPackageAuditLogAsync.rejected, (state: any) => {
        state.packageDocumentsListDictionary = {
          ...state.packageDocumentsListDictionary,
          [DocDownloadType.AuditLog]: []
        }
        state.alertUser = {
          type: 'error',
          text: 'An error occurred. Please try again.'
        }
      })
      // Fetch Package Singular Documents
      .addCase(fetchPackageDocsAsync.pending, (state: any) => {
        state.packageDocumentsListDictionary = {
          ...state.packageDocumentsListDictionary,
          [DocDownloadType.Individual]: []
        }
        state.alertUser = null
      })
      .addCase(fetchPackageDocsAsync.fulfilled, (state: any, action: any) => {
        // TODO: remove casing transform at some point
        const updatedPayload = transformPropToPascal(action.payload)
        state.packageDocumentsListDictionary = {
          ...state.packageDocumentsListDictionary,
          [DocDownloadType.Individual]: updatedPayload
        }
      })
      .addCase(fetchPackageDocsAsync.rejected, (state: any) => {
        state.packageDocumentsListDictionary = {
          ...state.packageDocumentsListDictionary,
          [DocDownloadType.Individual]: []
        }
        state.alertUser = {
          type: 'error',
          text: 'An error occurred. Please try again.'
        }
      })
      // Fetch Package Merged Documents
      .addCase(fetchPackageDocsMergedAsync.pending, (state: any) => {
        state.packageDocumentsListDictionary = {
          ...state.packageDocumentsListDictionary,
          [DocDownloadType.MergedDocs]: []
        }
        state.alertUser = null
      })
      .addCase(
        fetchPackageDocsMergedAsync.fulfilled,
        (state: any, action: any) => {
          // TODO: remove casing transform at some point
          const updatedPayload = transformPropToPascal(action.payload)
          state.packageDocumentsListDictionary = {
            ...state.packageDocumentsListDictionary,
            [DocDownloadType.MergedDocs]: updatedPayload
          }
        }
      )
      .addCase(fetchPackageDocsMergedAsync.rejected, (state: any) => {
        state.packageDocumentsListDictionary = {
          ...state.packageDocumentsListDictionary,
          [DocDownloadType.MergedDocs]: []
        }
        state.alertUser = {
          type: 'error',
          text: 'An error occurred. Please try again.'
        }
      })
      // Open Package Presigned Url
      .addCase(openPresignedUrl.pending, (state: any) => {
        state.alertUser = null
      })
      .addCase(openPresignedUrl.fulfilled, (state: any) => {
        state.alertUser = null
      })
      .addCase(openPresignedUrl.rejected, (state: any) => {
        state.alertUser = {
          type: 'error',
          text: 'Unable to download document. Please try downloading again.'
        }
      })
      // Upload Package File(s)
      .addCase(beginUploadDocumentsAsync.pending, () => {
      })
      .addCase(beginUploadDocumentsAsync.fulfilled, () => {
      })
      .addCase(beginUploadDocumentsAsync.rejected, () => {
      })
      // Fetching Users Config for creating packages
      .addCase(fetchCreatePackageUserConfigAsync.pending, (state: IESignPackagesState) => {
        state.createPackage.userConfig = null
      })
      .addCase(fetchCreatePackageUserConfigAsync.fulfilled, (state: any, action: any) => {
        state.createPackage.userConfig = action.payload
      })
      .addCase(fetchCreatePackageUserConfigAsync.rejected, () => {
      })
      .addCase(getDocumentStatusAsync.pending, () => {
      })
      .addCase(getDocumentStatusAsync.fulfilled, (state: IESignPackagesState, action: any) => {
        const updatePackage = Object.assign({}, state.createPackage)
        
        action.payload.documentStatuses.forEach((payloadItem: any) => {
          const uploadToUpdate = updatePackage.uploads.find(upload => upload.name === payloadItem.docName);
          if (uploadToUpdate) {
            const { status, errorMessage, errorUploading, uploading } = getMappedUploadStatus(payloadItem.status)
            uploadToUpdate.status = status;
            uploadToUpdate.errorMessage = errorMessage;
            uploadToUpdate.errorUploading = errorUploading;
            uploadToUpdate.uploading = uploading;
          }
        });
        updatePackage.allUploadsComplete = updatePackage.uploads.every(upload => !upload.uploading);
        state.createPackage = updatePackage;
        state.createPackage.createPackageError = null;
      })
      .addCase(getDocumentStatusAsync.rejected, (state: IESignPackagesState, action: any) => {
        const updatePackage = Object.assign({}, state.createPackage)

        for (let i = 0; i < updatePackage.uploads.length; i++) {
          updatePackage.uploads[i].uploading = false
          updatePackage.uploads[i].status = DocumentUploadStatus.Failed
          updatePackage.uploads[i].errorMessage = action.error.message
          updatePackage.uploads[i].errorUploading = true
        }
        updatePackage.createPackageError = null
        state.createPackage = updatePackage
      })
  }
})

export const selectUploadId = (state: RootState) => state.esign.createPackage.uploadId;

export const {
  resetPackageDetails,
  updatePackage,
  cancelCreatePackage,
  resetCreatePackage,
  resetDocumentsListDictionary,
  uploadDocumentSuccess,
  uploadDocumentError,
  setCreatePackageUploadId
} = esignSlice.actions

export default esignSlice.reducer
