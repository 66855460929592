import axiosInterceptor from '../../shared/utils/axios'
import axios from 'axios'

export const fetchTransaction = (query: string, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const url = config.baseURL + '/sessions/session-details?' + query
    axiosInterceptor.get(url)
      .then((response) => {
        resolve({
          data: response.data.Session
        })
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request canceled...
          // resolve('')
        } else {
          let errorText:any = 'Something Went Wrong'
          if (error.message) { errorText = error.message }
          if (error.Message) { errorText = error.Message }
          if (error?.response?.data?.Message) { errorText = error.message }
          reject(errorText)
        }
      })
  })
}

export const fetchDocuments = (query: string, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const url = config.baseURL + '/sessions/retrieve-documents?' + query
    axiosInterceptor.get(url)
      .then((response) => {
        resolve({
          data: response.data
        })
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const fetchAuditLog = (query: string, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const url = config.baseURL + '/sessions/audit-log?' + query
    axiosInterceptor.get(url)
    .then((response) => {
      resolve({
        data: response.data
      })
    })
    .catch((error) => reject(error))
  })
}

export const unlockParty = (payload: Record<string, unknown>, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const url = config.baseURL + '/sessions/unlock-party'
    axiosInterceptor.put(url, payload)
      .then((response) => {
        if (response && response.data && !response.data.IsSuccessful) {
          reject(response.data.ErrorMessage)
        }
        resolve(response.data)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          /// Request cancelled...
          resolve('')
        } else {
          reject((error?.response?.data?.Message) ? error.response.data.Message : error.Message)
        }
      })
  })
}

export const sendPartyLinks = (query: string, config: any) => {
  return new Promise<any>((resolve, reject) => {
    const url = config.baseURL + '/sessions/send-links?' + query
    axiosInterceptor.post(url)
        .then((response) => {
            if (response.status == 200) {
                resolve(response);
            }
        })
        .catch((error) => {
          reject(error);
        })
  })
}