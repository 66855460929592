import { createTheme } from '@mui/material/styles';

let CustomTheme = createTheme({
  palette: {
    primary: {
    light: '#77A9DF',
    main: '#143B6B'
    // dark: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#8ca1bb',
      main: '#538fda',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#101e2f'
    }
  }
})

CustomTheme = createTheme(CustomTheme, {
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&.MuiTableCell-head': {
            fontWeight: 600,
            fontSize: '12px',
            color: 'black',
            textTransform: 'uppercase'
          }
        }
      }
    },
  },
});

export default CustomTheme
