import { rest } from 'msw'
import { mockAllSessionsData, mockDisclosureData } from './models/mockSessionsData'

export const ordersHandlers = [
  rest.get('https://api.vision.dev.solex.com/sessions/super-admin-status', (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ResponseMessage: true
      })
    )
  }),
  rest.get('https://api.vision.dev.solex.com/sessions', (req, res, ctx) => {
    const statuses = req.url.searchParams.get('Statuses')

    if (statuses === 'pre_disclosure,disclosure,redisclosure,tla_redisclosure,adverse_action,appraisal,processing,underwriting,preclosing,closing,shipping') {
      // return a mocked sessions response if in table view
      return res(
        ctx.json({
          Sessions: mockAllSessionsData,
          TotalCount: 4259
        })
      )
    } else if (statuses === 'disclosure') {
      return res(
        ctx.json(mockDisclosureData)
      )
    }
    return res(
      ctx.status(200),
      ctx.json({
        Sessions:[],
        TotalCount:0
      })
    )
  }),
  rest.get('https://api.vision.dev.solex.com/sessions/client-list', (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        ResponseList:[{
          ClientId:"1",
          ClientName:"PKTM"
        }]
      })
    )
  }),
  rest.get('https://api.vision.dev.solex.com/sessions/lo-list', (_, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        LoanOfficers: [
          {
            Name: " ",
            Email: "loanofficer@docutech.com"
          },
          {
            Name: "Lisa Simpson",
            Email: "lsimpson@firstam.com"
          },
          {
            Name: "Kat Testing",
            Email: "kpetrik@firstam.com"
          },
          {
            Name: "Tim Tom",
            Email: "drowbury@firstam.com"
          },
          {
            Name: "Jason Jackson",
            Email: "SolexVisionTestLO@docutech.com"
          },
          {
            Name: "Sabrina Testing",
            Email: "svoeller@firstam.com"
          },
          {
            Name: " ",
            Email: "testuser4@sxvs.com"
          }
        ]
    })
    )
  })
]