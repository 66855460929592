import jwtDecode from 'jwt-decode'
import * as ls from 'local-storage'

/**
 * @description tokenValidator checks if tokens exist and passes them back from local storage
 * @returns Object - tokens are available
 * @returns null if NO TOKENS available
 */

export const tokenValidator = async () => {
  const AccessToken: string = ls.get('AccessToken')
  const IdToken: string = ls.get('IdToken')
  if (AccessToken && IdToken) {
    return { valid: true, AccessToken: AccessToken, IdToken: IdToken }
  }
  return null
}

/**
 * @description tokenExpired will check if the token has expired
 * @returns false - if AccessToken has NOT expired
 * @returns true - if AccessToken HAS expired
 * @returns null if NO TOKENS available
 */

export const tokenExpired = async (dev = false) => {
  const AccessToken: string =  await ls.get('AccessToken')
  if(!AccessToken) return null
  const tokenDecoded:any = jwtDecode(AccessToken)
  const expiry = new Date(tokenDecoded.exp * 1000)
  const now = new Date() 
  if(dev) {
    // forces dev to have roughly 2 min expire time
    expiry.setMinutes(expiry.getMinutes() - 58)
    expiry.setHours(expiry.getHours() - 9)
    const diff = new Date(expiry.getTime() - now.getTime())
    if(now.getTime() < expiry.getTime()) {
      console.warn('Token Expires:' + diff.getMinutes() + 'm ' + diff.getSeconds() + 's')
    }
  }
  return (now.getTime() < expiry.getTime()) ?  false : true
}
